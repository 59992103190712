import { createApp } from "/node_modules/.vite/deps/vue.js?v=fbf23d77";
import App from "/src/App.vue";
import router from "/src/router/index.js";
import i18n from "/src/i18n.js";
import vuetify from "/src/plugins/vuetify.js";
import filters from "/src/filters.js";
import store from "/src/store/index.js";
import timeago from "/node_modules/.vite/deps/vue-timeago3.js?v=fbf23d77";
import { useCookies } from "/node_modules/.vite/deps/vue3-cookies.js?v=fbf23d77";
import Clipboard from "/node_modules/.vite/deps/v-clipboard.js?v=fbf23d77";
import VueTurnstile from "/node_modules/.vite/deps/vue-turnstile.js?v=fbf23d77";

const { cookies } = useCookies();
i18n.global.locale.value = cookies.get("lang") || "en";
vuetify.locale.current = cookies.get("lang") || "en";
document.querySelector("html").setAttribute("lang", cookies.get("lang") || "en");

const timeagoOptions = {
  converterOptions: {
    includeSeconds: true,
    addSuffix: true,
    useStrict: true,
  },
};

const app = createApp(App);
app.use(VueTurnstile);
app.use(Clipboard);
app.use(router);
app.use(i18n);
app.use(vuetify);
app.use(timeago, timeagoOptions);
app.use(filters);
app.use(store);
app.mount("#app");

